import React, { useState, Fragment } from "react";

// Libraries
import { connect } from "react-redux";
import { createSelector } from "reselect";

// Core
import { db } from "../../../core/firebase";
import { getSession } from "../../../core/session";
import { setNotification } from "../../../core/notifications";

// Component
import Image from "../../components/images/image";
import FormMeedenken from "../../components/forms/meedenken";
import FormAanvulling from "../../components/forms/aanvulling";
import { IconRight, IconSpin } from "../../components/icons";

const Completed = ({ session, setNotification }) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [avgContact, setAvgContact] = useState(false);
    const [loading, setLoading] = useState(false);

    const [openMeedenken, toggleMeedenken] = useState(false);
    const [openAanvulling, toggleAanvulling] = useState(false);

    const submitForm = () => {
        setLoading(true);

        const response = db
            .collection("sessions")
            .doc(session.uid)
            .update({
                email,
                name,
                avgContact,
            })
            .then((doc) => ({ doc }))
            .catch((error) => ({ error }));

        if (response.error) {
            setNotification({
                error: true,
                message:
                    "Er is een fout opgetreden bij het opslaan van de informatie.",
            });
        } else {
            setNotification({
                message: "Het formulier is succesvol verstuurd!",
            });
        }

        setLoading(false);
    };

    const openAvg = !!(email.length && name.length);
    const enableSubmit = !!(email.length && name.length && avgContact);

    return (
        <Fragment>
            <div className="flex flex-center pt-l">
                <div className="container container-m center pt-l pb-l">
                    <div className="flex flex-center flex-align">
                        <Image
                            height="160px"
                            src={`${process.env.PUBLIC_URL}/logos/de_horsten_logo.png`}
                        />
                    </div>
                    <div className="flex flex-center relative">
                        <h2 className="leading-tight center text-20">
                            Bedankt voor uw mening over de Horsten We waarderen
                            uw aandeel en nemen alle resultaten mee. Om u te
                            bedanken voor uw inbreng doet u automatisch mee voor
                            leuke prijzen. Hiervoor hebben we alleen een
                            mailadres en naam nodig:
                        </h2>

                        {!session.avgContact && (
                            <div>
                                <h3 className="pt-m fs-m a-l mb-s">Naam</h3>
                                <input
                                    className="input"
                                    placeholder="Naam"
                                    value={name}
                                    type="text"
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <h3 className="fs-m a-l mb-s mt">Emailadres</h3>
                                <input
                                    className="input"
                                    placeholder="Emailadres"
                                    value={email}
                                    type="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                />

                                {openAvg && (
                                    <div className="mt flex">
                                        <input
                                            type="checkbox"
                                            id="avg"
                                            name="avg"
                                            onChange={() =>
                                                setAvgContact(!avgContact)
                                            }
                                            value={avgContact}
                                        />
                                        <label
                                            className="a-l ml c-gray"
                                            htmlFor="avg"
                                        >
                                            Ik stem toe dat de gemeente
                                            Apeldoorn mijn naam en e-mailadres
                                            opslaat en mij mag benaderen.
                                        </label>
                                    </div>
                                )}
                                <button
                                    className="btn full mt-m mb-m"
                                    disabled={!enableSubmit}
                                    onClick={submitForm}
                                >
                                    <span className="fs-xs">
                                        {enableSubmit
                                            ? "Versturen"
                                            : "Vul de gegevens in"}
                                    </span>
                                    {loading ? (
                                        <IconSpin className="ml icon icon-sm spin" />
                                    ) : (
                                        <IconRight className="ml icon icon-sm" />
                                    )}
                                </button>
                            </div>
                        )}

                        <p className="center text-20 mt-m">
                            Wilt u graag meedenken over bepaalde thema's?
                        </p>
                        <button
                            className="btn full mt mb-m"
                            onClick={() => toggleMeedenken(!openMeedenken)}
                        >
                            <span className="fs-xs">Klik hier</span>
                        </button>
                        <p className="center text-20">
                            Heeft u iets gemist, wilt u aanvullingen doen of een
                            goed idee aandragen?
                        </p>
                        <button
                            className="btn full mt mb-m"
                            onClick={() => toggleAanvulling(!openAanvulling)}
                        >
                            <span className="fs-xs">Klik hier</span>
                        </button>
                    </div>
                </div>
            </div>
            <FormMeedenken
                open={openMeedenken}
                toggle={() => toggleMeedenken(!openMeedenken)}
            />
            <FormAanvulling
                open={openAanvulling}
                toggle={() => toggleAanvulling(!openAanvulling)}
            />
        </Fragment>
    );
};

const state = createSelector(getSession, (session) => ({ session }));
export default connect(state, { setNotification })(Completed);
